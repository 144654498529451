import { StaticQuery, graphql, Link } from "gatsby"
import React from "react"

import logo from "../images/chom.png"

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        wordpressSiteMetadata {
          name
        }
        wordpressWpApiMenusMenusItems(name: { eq: "Main Menu" }) {
          items {
            title
            object_slug
          }
        }
      }
    `}
    render={data => (
      <nav className="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark">
        <div className="container">
            <Link to="/">
              <a className="navbar-brand logo" href="/">
                  <img src={logo} alt={data.wordpressSiteMetadata.name} className="logo-dark" />
                  <img src={logo} alt={data.wordpressSiteMetadata.name} className="logo-light" />
              </a>
            </Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <i className="mdi mdi-menu"></i>
            </button>
            
            <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav ml-auto">
                  {data.wordpressWpApiMenusMenusItems.items.map(item => (
                    <li key={item.object_slug} className="nav-item active">
                      <Link
                        to={`/${item.object_slug}`}
                        style={{
                          color: `white`,
                          textDecoration: `none`,
                          fontFamily: `sans-serif`,
                        }}
                      >
                        <a href={`/${item.object_slug}`} class="nav-link">
                          {item.title}
                        </a>
                      </Link>
                    </li>
                  ))}
                </ul>
            </div>
        </div>
    </nav>
    )}
  />
)

export default Header