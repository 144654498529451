import { StaticQuery, graphql, Link } from "gatsby"
import React from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebook, faTwitter, faLinkedin } from "@fortawesome/free-brands-svg-icons"

import logo from "../images/chom.jpg"

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        wordpressSiteMetadata {
          name
          description
        }
        about: wordpressWpApiMenusMenusItems(name: { eq: "About" }) {
          items {
            title
            object_slug
          }
        }
        services: wordpressWpApiMenusMenusItems(name: { eq: "Services" }) {
          items {
            title
            object_slug
          }
        }
        other: wordpressWpApiMenusMenusItems(name: { eq: "Other" }) {
          items {
            title
            object_slug
          }
        }
      }
    `}
    render={data => (
        <>
            <section className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="mb-5">
                                <Link to="/">
                                    <img src={logo} alt={data.wordpressSiteMetadata.name} className="footer-logo" />
                                </Link>
                                <p className="text-white-50 my-4 font-size-15">
                                    {data.wordpressSiteMetadata.description}
                                </p>
                                <ul className="list-inline footer-social-icon-content">
                                    <li className="list-inline-item mr-4">
                                        <Link to="https://facebook.com" className="footer-social-icon">
                                            <FontAwesomeIcon icon={faFacebook} />
                                        </Link>
                                    </li>
                                    <li className="list-inline-item mr-4">
                                        <Link to="https://facebook.com" className="footer-social-icon">
                                            <FontAwesomeIcon icon={faTwitter} />
                                        </Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link to="https://facebook.com" className="footer-social-icon">
                                            <FontAwesomeIcon icon={faLinkedin} />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="col-lg-7 offset-lg-1">
                            <div className="row">
                                <div className="col-md-4">
                                    <h6 className="text-white text-uppercase mb-4">About</h6>
                                    <ul className="list-unstyled footer-sub-menu">
                                        {data.about.items.map(item => (
                                            <li key={item.object_slug}>
                                                <Link to={`/${item.object_slug}`} className="footer-link">
                                                    {item.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                <div className="col-md-4">
                                    <h6 className="text-white text-uppercase mb-3">Services</h6>
                                    <ul className="list-unstyled footer-sub-menu">
                                        {data.services.items.map(item => (
                                            <li key={item.object_slug}>
                                                <Link to="#" className="footer-link">
                                                    {item.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                                <div className="col-md-4">
                                    <h6 className="text-white text-uppercase mb-3">Other</h6>
                                    <ul className="list-unstyled footer-sub-menu">
                                        {data.other.items.map(item => (
                                            <li key={item.object_slug}>
                                                <Link to="#" className="footer-link">
                                                    {item.title}
                                                </Link>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            <div className="footer-alt py-3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="text-center">
                                <p className="text-white-50 font-size-15 mb-0">
                                    2020 © {data.wordpressSiteMetadata.name}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )}
  />
)

export default Header